<template>
  <div>
    <hr
      style="
        height: 1.5px;
        border-width: 0;
        color: gray;
        background-color: gray;
      "
    />
    <b-row cols="12" class="mb-1 alert-heading m-1">
      <b-col class="d-flex align-items-center justify-content-center">
        <b-form-group class="mr-1" style="width: 500px" label="Licensee">
          <v-select
            :disabled="current_step != 1 ? true : false"
            @input="getAllRevenueData"
            v-model="selectedCustomer"
            placeholder="Licensee"
            label="company"
            :options="optionCustomer"
          />
        </b-form-group>

        <b-form-group style="width: 500px" label="Title">
          <v-select
            :disabled="current_step != 1 ? true : false"
            @input="getAllRevenueData"
            @search="GetAllMovie"
            v-model="filterTempMovie"
            placeholder="Title"
            label="full_title"
            :options="optionTempMovie"
          />
        </b-form-group>

        <!-- submit and reset -->
      </b-col>
    </b-row>
    <hr
      style="
        height: 1.5px;
        border-width: 0;
        color: gray;
        background-color: gray;
      "
    />

    <b-card no-body>
      <b-table
        bordered
        v-if="$can('read', 'revenue') && current_step == 1"
        ref="selectableTable"
        :fields="fields"
        :items="revenueDataList"
        responsive
        empty-text="No  records found"
        show-empty
        small
        hover
      >
        <template #head(sr)="data">
          <b-badge v-if="totalRows" variant="success">{{ totalRows }}</b-badge>
        </template>
        <!-- <template #cell(sr)="data">
            {{ data.index + 1 + (currentPage - 1) * limit }}
          </template> -->
        <template v-if="selectedCustomer" #head(selected)="data">
          <b-form-checkbox
            v-if="selectedCustomer"
            @change="selectAllRows"
            v-model="selectAllStatus"
            value="1"
            unchecked-value="0"
          >
          </b-form-checkbox>
        </template>
        <template #cell(company)="data">
          <div class="text-nowrap">
            <span
              ><p>Lisensee : {{ data.item.company }}</p>
              <p>Licensor : {{ data.item.licensor_company }}</p></span
            >
          </div>
        </template>
        <template v-if="selectedCustomer" v-slot:cell(selected)="data">
          <b-form-checkbox
            v-if="selectedCustomer"
            v-model="selectedRevenueItems"
            :value="data.item"
          >
          </b-form-checkbox>
        </template>

        <template #cell(date)="data">
          <div class="text-nowrap">
            <span>
              Revenue Dt. :
              <b>
                {{
                  data.item.date
                    ? moment(data.item.date).format("DD/MM/YYYY")
                    : ""
                }}</b
              ><br
            /></span>
            <span>
              Royalty Dt. :
              <b>{{
                data.item.revenue_royalty_date
                  ? moment(data.item.revenue_royalty_date).format("DD/MM/YYYY")
                  : ""
              }}</b></span
            >
          </div>
        </template>

        <template #cell(unit_sold)="data">
          {{
            data.item.right_type == "1"
              ? data.item.addmission
              : data.item.unit_sold
          }}
        </template>
        <template #cell(share_net)="data">
          CHF
          {{
            data.item.right_type == "1"
              ? formatCurrency(data.item.boNet)
              : formatCurrency(data.item.share_net)
          }}
        </template>
        <template #cell(rentalNet)="data">
          {{
            data.item.right_type == "1"
              ? formatCurrency(data.item.rentalNet)
              : "NA"
          }}
        </template>

        <template #cell(title)="data">
          <b-badge variant="light-primary">{{ data.item.uniqueId }}</b-badge>
          {{ data.item.title }}
        </template>

        <!-- <template #cell(right_type)="data">
          {{ data.value == "1" ? "Theatrical" : "Other" }}
        </template> -->
      </b-table>
      <template v-if="current_step == 1" #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                @change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                :limit="15"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
      <div v-if="current_step == 2">
        <hr />
        <b-row class="m-1">
          <b-col md="6">
            <b-form-group class="ml-2" label="Payment Account">
              <v-select
                @input="setDefaultCurrency"
                :clearable="false"
                v-model="selectedAccount"
                placeholder="Account"
                :options="optionAccounts"
              >
                <template slot="selected-option" slot-scope="option">
                  <div>{{ option.currency }} ({{ option.account }})</div>
                </template>

                <template slot="option" slot-scope="option">
                  <div>{{ option.currency }} ({{ option.account }})</div>
                </template></v-select
              >
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <div class="mt-2">
              <b class="mr-1"
                ><b-badge pill variant="light-primary"
                  >Invoice Revenue Relevant For Royalty Report?</b-badge
                ></b
              >
              <b-form-checkbox
                v-model="relevent_for_royalty"
                class="custom-control-primary"
                id="revenue_upload_validation_child"
                switch
                :unchecked-value="0"
                inline
                >{{
                  relevent_for_royalty == false ? "No" : "Yes"
                }}</b-form-checkbox
              >
            </div>
          </b-col>

          <!-- <b-col md="6">
              <b-form-group label="Relevent For Royalty Report">
                <b-form-checkbox
                  v-model="relevent_for_royalty"
                  class="custom-control-primary"
                  id="revenue_upload_validation_child"
                  switch
                  :unchecked-value="0"
                >
                  <span class="switch-icon-left"> </span>
                  <span class="switch-icon-right"> </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col> -->
        </b-row>

        <div>
          <b-card-text class="previewClass">
            <html>
              <head>
                <meta charset="utf-8" />
                <title>Invoice</title>
              </head>
              <body>
                <header>
                  <!-- <h1>Invoice</h1> -->
                  <address>
                    <div
                      v-html="orgnizationData ? orgnizationData.address : ''"
                    ></div>
                    <!-- <p>Praesens-Film AG</p>
                      <p>Münchhaldenstrassse 10</p>
                      <p>Postfach</p>
                      <p>CH-9034 Zürich</p>
                      <p>Phone +41 44 422 38 32</p>
                      <p>www.praesens.com</p>
                      <p>VAT: CHE-102.645.295</p> -->
                  </address>
                </header>

                <header class="licensorHeador">
                  <address class="licensorClass">
                    <p>
                      <!-- <b-form-group label="Licensor"> -->

                      <!-- </b-form-group> -->
                    </p>
                    <div>
                      <p class="vendorNameClass">
                        <b>{{
                          selectedCustomer ? selectedCustomer.company : ""
                        }}</b>
                      </p>
                      <p class="vendorNameClass">
                        {{ selectedCustomer ? selectedCustomer.street : "" }}
                      </p>
                      <p>
                        {{ selectedCustomer ? selectedCustomer.zip_code : "" }}
                        {{ selectedCustomer ? selectedCustomer.city : "" }}
                      </p>
                      <p>
                        {{ selectedCustomer ? selectedCustomer.state : "" }}
                      </p>
                      <p>
                        {{
                          selectedCustomer ? selectedCustomer.country_name : ""
                        }}
                      </p>
                      <!-- <p>{{ selectedCustomer ? selectedCustomer.phone : "" }}</p> -->
                    </div>
                  </address>
                  <address>
                    <p>Invoice Date : {{ moment().format("DD/MM/YYYY") }}</p>
                  </address>
                </header>

                <article>
                  <table class="inventory">
                    <thead>
                      <tr>
                        <th style="width: 250px">
                          <span>Title</span>
                        </th>
                        <th><span>Right</span></th>

                        <th>
                          <span>Territory</span>
                        </th>

                        <th><span>Quantity</span></th>
                        <th><span>Total (CHF)</span></th>
                        <th>
                          <span
                            >Exchange Rate (CHF to {{ defaultCurrency }} )</span
                          >
                        </th>
                        <th>
                          <span>Final Amount ({{ defaultCurrency }})</span>
                        </th>
                        <th style="width: 150px">
                          <span>Due Date</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in selectedRevenueItems"
                        :key="index"
                      >
                        <td>
                          <a class="cut" @click="onRemoveRevenue(item, index)"
                            >-</a
                          >
                          <span> {{ item.uniqueId }}-{{ item.title }}</span>
                        </td>
                        <td>
                          <span> {{ item.rightIdentifier }} </span>
                        </td>
                        <td>
                          <span> {{ item.territory }}</span>
                        </td>

                        <td class="text-right">
                          <span>{{ item.unit_sold ? item.unit_sold : 1 }}</span>
                        </td>
                        <td class="text-right">
                          <b>
                            <span>
                              CHF {{ formatCurrency(item.share_net) }}</span
                            ></b
                          >
                        </td>
                        <td>
                          <span
                            ><b-form-input
                              style="border: none !important"
                              contenteditable
                              size="sm"
                              v-model="exchangeRate"
                              id="invoice-data-sales-person"
                              placeholder="Exh. Rt"
                              type="number"
                              @input="onUpdateExchange"
                          /></span>
                        </td>
                        <td>
                          <span
                            ><b-form-input
                              disabled
                              style="border: none !important"
                              size="sm"
                              v-model="item.share_net_final"
                              id="invoice-data-sales-person"
                              placeholder="Exh. Rt"
                              type="number"
                              @input="onUpdateManualAmount()"
                          /></span>
                        </td>
                        <td>
                          <span>
                            <flat-pickr
                              :config="{
                                ...$flatPickrConfig,
                              }"
                              class="form-control"
                              v-model="item.due_date"
                          /></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>

                <aside style="margin-top: -30px !important">
                  <h1><span>Other Charges</span></h1>
                  <article>
                    <table class="inventory">
                      <thead>
                        <tr>
                          <th><span>Additional Costs</span></th>
                          <!-- <th>
                      <span>Territory</span>
                    </th> -->
                          <th>
                            <span>Price ({{ defaultCurrency }})</span>
                          </th>
                          <th style="width: 100px"><span>Quantity</span></th>
                          <th>
                            <span>Total Price ({{ defaultCurrency }})</span>
                          </th>
                          <th style="width: 120px">
                            <span
                              >Exchange Rate ( {{ defaultCurrency }} to CHF
                              )</span
                            >
                          </th>
                          <th style="width: 120px">
                            <span>Total Price (CHF)</span>
                          </th>
                          <th style="width: 150px">
                            <span>Due Date</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in otherChargesArray"
                          :key="index"
                        >
                          <td>
                            <a
                              class="cut"
                              @click="removeOtherCharges(itemO, indexO)"
                              >-</a
                            ><span>
                              <b-form-input
                                style="border: none !important"
                                contenteditable
                                size="sm"
                                v-model="item.otherChargeName"
                                id="invoice-data-sales-person"
                                placeholder="Additional Costs"
                                @input="onUpdateAmount()"
                              />
                            </span>
                          </td>

                          <td class="text-right">
                            <span>
                              <b-form-input
                                style="border: none !important"
                                contenteditable
                                size="sm"
                                v-model="item.otherChargeValue"
                                type="number"
                                id="invoice-data-sales-person"
                                placeholder="Amount"
                                @input="onUpdateAmount()"
                            /></span>
                          </td>
                          <td>
                            <span
                              ><b-form-input
                                style="border: none !important"
                                contenteditable
                                size="sm"
                                v-model="item.otherChargeQnt"
                                id="invoice-data-sales-person"
                                placeholder="Quant"
                                type="number"
                                @input="onUpdateAmount(item, index)"
                            /></span>
                          </td>
                          <td class="text-right">
                            <span>{{
                              parseFloat(item.otherChargeTotal).toFixed(2)
                            }}</span>
                          </td>
                          <td>
                            <span
                              ><b-form-input
                                style="border: none !important"
                                contenteditable
                                size="sm"
                                v-model="exchangeRate"
                                id="invoice-data-sales-person"
                                placeholder="Exh. Rt"
                                type="number"
                                @input="onUpdateExchange"
                            /></span>
                          </td>
                          <td>
                            <span
                              ><b-form-input
                                disabled
                                style="border: none !important"
                                size="sm"
                                v-model="item.final_amount"
                                id="invoice-data-sales-person"
                                placeholder="Final Amt"
                                type="number"
                            /></span>
                          </td>
                          <td>
                            <span>
                              <flat-pickr
                                :config="{
                                  ...$flatPickrConfig,
                                }"
                                class="form-control"
                                v-model="item.due_date_otherCharge"
                            /></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <a @click="addNewOtherCharge" class="add">+</a>
                  </article>
                </aside>
                <article>
                  <table class="balance">
                    <tr>
                      <th><span contenteditable>Sub-Total</span></th>
                      <td class="text-right">
                        <span
                          >{{ defaultCurrency }}
                          <b>
                            {{
                              roundToTwo(
                                (
                                  parseFloat(subTotal) +
                                  parseFloat(totalOtherCharge)
                                ).toFixed(2)
                              )
                            }}</b
                          ></span
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          >VAT %
                          <span class="text-right">
                            <v-select
                              :reduce="(label) => label.lbl_id"
                              @input="onTaxChange"
                              :clearable="false"
                              v-model="selectedTax"
                              placeholder="Select Tax"
                              label="lbl_name"
                              :options="all_tax"
                            />
                            <b-form-input
                              size="sm"
                              contenteditable
                              class="text-right"
                              style="border: none !important"
                              v-model="vatPercentage"
                              id="invoice-data-sales-person"
                              placeholder="Vat%"
                              @input="onUpdateVatPercentage()"
                            /> </span
                        ></span>
                      </th>
                      <td class="text-right">
                        <span
                          >{{ defaultCurrency }}
                          <b>{{
                            roundToTwo(
                              (
                                (parseFloat(vatPercentage ? vatPercentage : 0) *
                                  (parseFloat(subTotal) +
                                    parseFloat(totalOtherCharge))) /
                                100
                              ).toFixed(2)
                            )
                          }}</b></span
                        >
                      </td>
                    </tr>
                    <tr>
                      <th><span>Total</span></th>
                      <td class="text-right">
                        <span
                          >{{ defaultCurrency }} <b> {{ totalAmount }}</b></span
                        >
                      </td>
                    </tr>
                  </table>
                </article>

                <article>
                  <b> VAT-UID</b>
                  <b-form-checkbox
                    v-model="isVatUid"
                    id="checkbox-1"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    VAT-UID :
                    <b-form-input
                      :disabled="isVatUid == '0' ? true : false"
                      size="sm"
                      v-model="VatUidNumber"
                      id="invoice-data-sales-person"
                      placeholder="VAT-UID"
                    />
                  </b-form-checkbox>
                </article>
                <article>
                  <b-form-textarea
                    contenteditable
                    id="textarea"
                    v-model="notes"
                    placeholder="Notes/Remarks"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </article>

                <article>
                  <b> TAX Liability</b>
                  <b-form-checkbox
                    v-model="isTaxLiability"
                    id="checkbox"
                    name="checkbox"
                    value="1"
                    unchecked-value="0"
                  >
                    According to the reverse charge system, tax liability
                    transfers to the recipient of services
                  </b-form-checkbox>
                </article>
              </body>
            </html>
          </b-card-text>
        </div>
      </div>
    </b-card>
    <b-row>
      <b-col class="m-1 d-flex justify-content-center">
        <b-button
          class="mr-1"
          v-if="current_step > 1"
          variant="outline-secondary"
          @click="onClickBack"
          >Back</b-button
        >
        <b-button
          :disabled="selectedRevenueItems.length <= 0 ? true : false"
          variant="primary"
          @click="onClickNext"
          >{{ current_step == "2" ? "Generate Invoice" : "Next" }}</b-button
        >
      </b-col>
    </b-row>
    <!-- </b-card> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BTabs,
  BTab,
  VBTooltip,
  BPagination,
  BProgressBar,
  BFormTextarea,
} from "bootstrap-vue";
import {
  GetVendorRevenueData,
  GenerateRevenueInvoicePdf,
  getAllInvoiceAccounts,
} from "@/apiServices/RevenueServies";
import vSelect from "vue-select";
import moment from "moment";

import { getAllVendor } from "@/apiServices/VendorServices";
import { getAllMovie } from "@/apiServices/MovieServices";
import Ripple from "vue-ripple-directive";

import flatPickr from "vue-flatpickr-component";
import { downloadFromURL } from "@/utils/helpers";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { formatCurrency } from "@/utils/helpers";
import { getOrganizationDetails } from "@/apiServices/OrganizationServices";
import { debounce } from "@/utils/helpers";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BProgress,
    BBadge,
    vSelect,
    BButton,
    BTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    flatPickr,

    BTabs,
    BTab,

    VBTooltip,
    BPagination,
    ToastificationContentVue,
    BFormTextarea,
    BProgressBar,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      orgnizationData: null,
      relevent_for_royalty: true,
      defaultCurrency: "CHF",
      selectedAccount: null,
      selectedTax: null,
      optionAccounts: [],
      isTaxLiability: "0",
      isVatUid: "0",
      VatUidNumber: null,
      current_step: 1,
      max_step: 2,
      BASE_URL: process.env.VUE_APP_BASEURL,
      stickyHeader: true,
      selectAllStatus: "0",
      filterTempTerritory: null,
      optionTempTerritory: [],
      filterTempRights: null,
      optionTempRights: [],
      filterTempMovie: null,
      optionTempMovie: [],
      revenueDateRange: null,
      selectedCustomer: null,
      selectedRight: null,
      optionCustomer: [],
      optionRights: [
        { title: "Theatrical Rights", value: 1 },
        { title: "Other Rights", value: 2 },
      ],
      notes: null,
      selectedStatus: null,
      setIsInvoice: false,
      loading: false,
      optionStatus: [
        { title: "Billed", value: 1 },
        { title: "Unbilled", value: 0 },
        // { title: "Generate Manual Invoice", value: 3 },
      ],
      otherChargesArray: [],
      subTotal: 0,
      totalOtherCharge: 0,
      exchangeRate: 1,
      vatPercentage: 0,
      moment: moment,
      currentPage: 1,
      totalPages: 0,
      limit: 500,
      all_tax: [],
      totalRows: null,
      perPageOptions: [25, 50, 100],
      fields: [
        { key: "sr", label: "SR" },

        { key: "selected", label: "" },
        // { key: "uniqueId", label: "Unique Id" },
        { key: "company", label: "Customer" },
        { key: "title", label: "Movie" },

        { key: "rightIdentifier", label: "Rights" },
        { key: "territory", label: "Territory" },
        { key: "share_net", label: "Share Net / Bo Net / RentalNet" },

        { key: "date", label: "Dates (DD/MM/YYYY)" },
        // { key: "revenue_royalty_date", label: "DATE FOR ROYALTY REPORT" },
        // { key: "right_type", label: "Revenue Type" },

        // { key: "unit_sold", label: "Unit Sold / Addmission" },

        // { key: "rentalNet", label: "RentalNet" },

        // { key: "updatedAt", label: "Last Updated" },
      ],

      revenueDataList: [],
      revenueDataListAll: [],
      selectedRevenueItems: [],
      showGenerateInvoice: false,
    };
  },
  computed: {
    progress: function () {
      return Math.round(100 / this.max_step) * this.current_step;
    },
    totalAmount() {
      let amount1 = (
        parseFloat(this.subTotal) + parseFloat(this.totalOtherCharge)
      ).toFixed(2);

      let amount2 = (
        (parseFloat(this.vatPercentage ? this.vatPercentage : 0) *
          (parseFloat(this.subTotal) + parseFloat(this.totalOtherCharge))) /
        100
      ).toFixed(2);

      let total = (parseFloat(amount1) + parseFloat(amount2)).toFixed(2);
      return this.roundToTwo(total);
    },
  },
  watch: {
    // limit() {
    //   this.getAllRevenueData();
    // },
    // currentPage() {
    //   this.getAllRevenueData();
    //   this.$refs["table_container"].scrollIntoView({ behavior: "smooth" });
    // },
    selectedRevenueItems() {
      if (this.selectedRevenueItems.length > 0) {
        if (this.selectedRevenueItems.length != this.revenueDataList.length) {
          this.selectAllStatus = "0";
        } else {
          this.selectAllStatus = "1";
        }
      } else {
      }
    },
  },
  async beforeMount() {
    this.getAllInvoiceAccounts();
    this.GetOriganizationDetails();
    this.getAllRevenueData();
    this.GetAllVendor();
    this.GetAllMovie();
    await this.$store.dispatch("master/setLabelMaster", {
      key: "tax",
      deleted: 0,
    });
    this.all_tax = this.$store.state.master.tax;

    if (this.all_tax.length) {
      this.selectedTax = this.all_tax[0].lbl_id;
      this.vatPercentage = this.all_tax[0].lbl_value2;
    }
  },
  methods: {
    formatCurrency,
    async getAllInvoiceAccounts() {
      try {
        const response = await getAllInvoiceAccounts();

        if (response.data.data) {
          this.optionAccounts = response.data.data;
          if (this.optionAccounts.length > 0) {
            this.selectedAccount = this.optionAccounts[0];
          }
        }
      } catch (err) {
        console.log("Error in getAllVendor ", err);
      }
    },
    async GetOriganizationDetails() {
      try {
        const response = await getOrganizationDetails();
        if (response.data.status) {
          //console.log(response.data.data);
          this.orgnizationData = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting organization details ", err);
      }
    },
    onTaxChange() {
      let filterTax = this.all_tax.filter((x) => {
        return x.lbl_id == this.selectedTax;
      });
      if (filterTax.length) {
        this.vatPercentage = filterTax[0].lbl_value2;
      }
    },
    async getAllRevenueData() {
      this.revenueDataList = [];
      this.revenueDataListAll = [];
      this.optionTempTerritory = [];
      this.optionTempRights = [];

      let aryMvi = [];
      let aryRt = [];
      let aryTer = [];
      try {
        let payload = {
          vendor_id: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : "",
          rightType: 2,
          billedStatus: 0,

          start_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[0]
            : "",
          end_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[1]
            : "",
          search: this.search,
          limit: this.limit,
          page: this.currentPage,
          movie_id:
            this.filterTempMovie && this.filterTempMovie.id
              ? this.filterTempMovie.id
              : null,
        };
        const response = await GetVendorRevenueData(payload);
        if (response.data.status) {
          this.revenueDataListAll = response.data.data.data;
          this.revenueDataList = response.data.data.data;

          if (
            response.data.data.pagination &&
            response.data.data.pagination.total
          ) {
            this.totalRows = response.data.data.pagination.total;
          }
        }
      } catch (err) {
        console.log("Error in GetVendorRevenueData ", err);
      }
    },
    async GetAllVendor() {
      try {
        const response = await getAllVendor({
          search: "",
          limit: "",
          page: "",
          deleted: "",
        });

        if (response.data) {
          this.optionCustomer = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllMovie(searchTerm) {
      // this.optionTempMovie = [];
      // if(!this._debouncedFetchMovies){
      //   this._debouncedFetchMovies = debounce( async(searchTerm) =>{
      try {
        // console.log(searchTerm)
        const response = await getAllMovie();
        if (response.data) {
          this.optionTempMovie = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
      //   });
      // }
      // this._debouncedFetchMovies(searchTerm);
    },

    onUpdateManualAmount(item, index) {
      // if (index >= 0) {
      //   this.selectedRevenueItems[index].share_net_final = (
      //     parseFloat(item.share_net) *
      //     (this.exchangeRate ? parseFloat(this.exchangeRate) : 1)
      //   ).toFixed(2);
      // }
      if (this.selectedRevenueItems && this.selectedRevenueItems.length > 0) {
        let sum = 0;
        this.selectedRevenueItems.forEach((item) => {
          item.share_net_final = (
            parseFloat(item.share_net ? item.share_net : 0) *
            parseFloat(this.exchangeRate ? this.exchangeRate : 1)
          ).toFixed(2);
          if (item.share_net_final) {
            sum += parseFloat(item.share_net_final);
          }
        });

        this.subTotal = sum;
      } else {
        this.subTotal = 0;
      }
    },
    roundToTwo(num) {
      if (num && typeof num == "number") {
        num = num.toFixed(2);
      }
      if (this.defaultCurrency != "CHF") {
        return num;
      }
      let stringNum = num.toString();
      let finalValue;

      try {
        let firstHalf = stringNum.substring(0, stringNum.length - 1);
        if (["0", "1", "2"].includes(stringNum[stringNum.length - 1])) {
          finalValue = firstHalf + "0";
        } else if (
          ["3", "4", "5", "6", "7"].includes(stringNum[stringNum.length - 1])
        ) {
          finalValue = firstHalf + "5";
        } else if (["8", "9"].includes(stringNum[stringNum.length - 1])) {
          finalValue = (Math.ceil(num * 10) / 10).toString() + ".0";
        }

        return parseFloat(finalValue).toFixed(2);
      } catch {
        logger.consoleErrorLog(req.originalUrl, "Error in roundToTwo", error);
        return 0;
      }
    },
    setDefaultCurrency() {
      if (this.selectedAccount && this.selectedAccount.currency) {
        this.defaultCurrency = this.selectedAccount.currency;
      }
    },
    removeOtherCharges(itemO, indexO) {
      this.otherChargesArray.splice(indexO, 1);
      this.onUpdateAmount();
    },
    onRemoveRevenue(itemR, indexR) {
      this.selectedRevenueItems.splice(indexR, 1);
      if (this.selectedRevenueItems && this.selectedRevenueItems.length > 0) {
        let sum = 0;
        this.selectedRevenueItems.forEach((item) => {
          if (item.unit_sold) {
            parseFloat(item.unit_sold).toFixed(2);
          }
          if (item.share_net_final) {
            parseFloat(item.share_net_final).toFixed(2);
            sum += parseFloat(item.share_net_final);
          }
        });

        this.subTotal = this.roundToTwo(sum);
      } else {
        this.subTotal = 0;
      }
    },
    showGenerateWarning() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to generate invoice.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.generateFinalInvoice();
          }
        });
    },

    async generateFinalInvoice() {
      this.loading = true;
      try {
        let payload = {
          selectedRevenueItems: this.selectedRevenueItems,
          selectedCustomer: this.selectedCustomer,
          filterTempMovie: this.filterTempMovie,
          otherChargesArray: this.otherChargesArray,
          tax_id: this.selectedTax,
          vatPercentage: parseFloat(this.vatPercentage),
          vatAmount: this.roundToTwo(
            (
              (parseFloat(this.vatPercentage ? this.vatPercentage : 0) *
                (parseFloat(this.subTotal) +
                  parseFloat(this.totalOtherCharge))) /
              100
            ).toFixed(2)
          ),
          subTotal: this.roundToTwo(
            (
              parseFloat(this.subTotal) + parseFloat(this.totalOtherCharge)
            ).toFixed(2)
          ),
          total: parseFloat(this.totalAmount).toFixed(2),
          notes: this.notes,
          exchangeRate: this.exchangeRate,
          defaultCurrency: this.defaultCurrency,
          selectedAccount: this.selectedAccount,
          isTaxLiability: this.isTaxLiability,
          isVatUid: this.isVatUid,
          VatUidNumber: this.VatUidNumber,
          for_royalty_report: this.relevent_for_royalty == true ? 1 : 0,
        };

        const response = await GenerateRevenueInvoicePdf(payload);

        if (response.data.status) {
          this.$router.push({
            name: "PreviewInvoice",
            params: {
              revenue_invoice_id: response.data.data.revenue_invoice_id,
            },
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.log("Error in getting movies list ", err);
      }
    },
    addNewOtherCharge() {
      let obj = {
        otherChargeName: "",
        otherChargeValue: 0,
        otherChargeQnt: 1,
        otherChargeTotal: 0,
        final_amount: 0,
        due_date_otherCharge: null,
      };

      this.otherChargesArray.push(obj);
    },
    onUpdateExchange() {
      this.onUpdateManualAmount();
      this.onUpdateAmount();
    },
    onUpdateAmount() {
      if (this.otherChargesArray && this.otherChargesArray.length > 0) {
        let sum = 0;
        this.otherChargesArray.forEach((item) => {
          item.otherChargeTotal =
            parseFloat(item.otherChargeValue) *
            parseFloat(item.otherChargeQnt ? item.otherChargeQnt : 1);
          item.final_amount =
            parseFloat(item.otherChargeTotal) *
            parseFloat(this.exchangeRate ? this.exchangeRate : 1);
          if (item.otherChargeValue && item.otherChargeName) {
            sum += parseFloat(item.final_amount);
          }
        });

        this.totalOtherCharge = sum;

        console.log(sum, "hhhh");
      } else {
        this.totalOtherCharge = 0;
      }
    },
    onUpdateVatPercentage() {},
    onClickNext: function () {
      if (this.current_step == 2) {
        this.showGenerateWarning();
      } else {
        if (this.selectedRevenueItems && this.selectedRevenueItems.length > 0) {
          let sum = 0;

          this.selectedRevenueItems.forEach((item) => {
            if (item.share_net) {
              item["share_net_final"] = item.share_net
                ? parseFloat(item.share_net).toFixed(2)
                : 0;
            }
            if (item.unit_sold) {
              parseFloat(item.unit_sold).toFixed(2);
            }
            if (item.share_net_final) {
              parseFloat(item.share_net_final).toFixed(2);
              sum += parseFloat(item.share_net_final);
            }
          });

          this.subTotal = sum;
        } else {
          this.subTotal = 0;
        }

        this.current_step++;
      }
    },
    onClickBack: function () {
      this.current_step--;
    },

    onPageChange() {
      this.$nextTick(() => {
        this.getAllRevenueData();
      });
    },
    selectAllRows() {
      if (this.selectAllStatus == "1") {
        this.selectedRevenueItems = this.revenueDataList;
      } else {
        this.selectedRevenueItems = [];
      }

      console.log(this.selectedRevenueItems);
    },

    async resetForm() {
      this.selectedCustomer = null;
      this.selectedRight = null;
      this.selectedStatus = null;
      this.revenueDateRange = null;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// .licensorHeador {
//   margin-top: -30px !important;
// }
.licensorClass {
  float: left;
  width: 35%;
}
/* content editable */

*[contenteditable] {
  border-radius: 0.25em;
  min-width: 1em;
  outline: 0;
}

*[contenteditable] {
  cursor: pointer;
}

*[contenteditable]:hover,
*[contenteditable]:focus,
td:hover *[contenteditable],
td:focus *[contenteditable],
img.hover {
  background: #def;
  box-shadow: 0 0 1em 0.5em #def;
}

span[contenteditable] {
  display: inline-block;
}

/* heading */

h1 {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
}

/* table */

table {
  font-size: 75%;
  table-layout: fixed;
  width: 100%;
}
table {
  border-collapse: separate;
  border-spacing: 2px;
}
th,
td {
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
}
th,
td {
  border-radius: 0.25em;
  border-style: solid;
}
th {
  background: #eee;
  border-color: #bbb;
}
td {
  border-color: #ddd;
}

/* page */

html {
  font: 16px/1 "Open Sans", sans-serif;
  overflow: auto;
  padding: 0.5in;
}
html {
  background: #999;
  cursor: default;
}

body {
  box-sizing: border-box;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  padding: 0.5in;
  width: 95%;
  border-radius: 5px !important;
  border: 2px solid grey;
}
body {
  background: #fff;
  border-radius: 1px;
  // box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
}

/* header */

header {
  margin: 0 0 3em;
}
header:after {
  clear: both;
  content: "";
  display: table;
}

header h1 {
  background: #000;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
header address {
  float: right;
  font-size: 75%;
  font-style: normal;
  line-height: 1.25;
  margin: 0 1em 1em 0;
}
header address p {
  margin: 0 0 0.25em;
}
header span,
header img {
  display: block;
  float: right;
}
header span {
  margin: 0 0 1em 1em;
  max-height: 25%;
  max-width: 60%;
  position: relative;
}
header img {
  max-height: 100%;
  max-width: 100%;
}
header input {
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* article */

article,
article address,
table.meta,
table.inventory {
  margin: 0 0 3em;
}
article:after {
  clear: both;
  content: "";
  display: table;
}
article h1 {
  clip: rect(0 0 0 0);
  position: absolute;
}

article address {
  float: left;
  font-size: 125%;
  font-weight: bold;
}

/* table meta & balance */

table.meta,
table.balance {
  float: right;
  width: 36%;
}
table.meta:after,
table.balance:after {
  clear: both;
  content: "";
  display: table;
}

/* table meta */

table.meta th {
  width: 40%;
}
table.meta td {
  width: 60%;
}

/* table items */

table.inventory {
  clear: both;
  width: 100%;
}
table.inventory th {
  font-weight: bold;
  text-align: center;
}

table.inventory td:nth-child(1) {
  width: 26%;
}
table.inventory td:nth-child(2) {
  width: 38%;
}
table.inventory td:nth-child(3) {
  text-align: right;
  width: 12%;
}
table.inventory td:nth-child(4) {
  text-align: right;
  width: 12%;
}
table.inventory td:nth-child(5) {
  text-align: right;
  width: 12%;
}

/* table balance */

table.balance th,
table.balance td {
  width: 50%;
}
table.balance td {
  text-align: right;
}

/* aside */

aside h1 {
  border: none;
  border-width: 0 0 1px;
  margin: 0 0 1em;
}
aside h1 {
  border-color: #999;
  border-bottom-style: solid;
}

/* javascript */

.add,
.cut {
  width: 18px !important;
  border-width: 1px;
  display: block;
  font-size: 0.8rem;
  padding: 0.25em 0.5em;
  float: left;
  text-align: center;
  width: 0.6em;
  margin-top: 1px;
}

.add,
.cut {
  background: lightgray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  border-radius: 0.5em;
  border-color: #0076a3;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.333);
}

.add {
  margin: -2.5em 0 0;
}

.add:hover {
  background: #00adee;
}

.cut {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -1.9em;
  opacity: 1;
}
.cut {
  -webkit-transition: opacity 100ms ease-in;
}

.cut {
  opacity: 1;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  .previewClass {
    box-sizing: content-box;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    text-decoration: none !important;
    vertical-align: top !important;
  }
  html {
    background: none;
    padding: 0;
    border: 0;
    box-sizing: content-box;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: top;
  }
  body {
    box-shadow: none;
    margin: 0;
  }
  span:empty {
    display: none;
  }
  .add,
  .cut {
    display: none;
  }
}
p {
  padding: 0px !important;
  font-size: 14px;
}

@page {
  margin: 0;
}
.notes {
  width: 40%;
}
</style>
